<template>
  <div>
    <div :class="$style.teachers">
      <Title text="Преподаватели" position="right" />

      <div :class="$style.teachers__header">
        <Input
          :class="$style.teachers__header_input"
          placeholder="Поиск"
          v-model="filterValue"
        />
        <div :class="$style.teachers__header_selects">
          <DefaultSelect
            v-model="filter.studentGrade"
            :items="grades"
            :font-size="'12px'"
            :style="{marginRight: '5px'}"
            placeholder="Класс"
            @input="(val) => inputSelect(val, 'studentGrade')"
          />
          <DefaultSelect
            v-model="filter.isVerified"
            :items="statuses"
            :font-size="'12px'"
            :style="{marginRight: '5px'}"
            placeholder="Статус"
            @input="(val) => inputSelect(val, 'isVerified')"
          />
          <DefaultSelect
            v-model="filter.lessonType"
            :items="orderLessonType"
            :font-size="'12px'"
            :style="{marginRight: '5px'}"
            placeholder="Тип подготовки"
            @input="(val) => inputSelect(val, 'lessonType')"
          />
          <DefaultSelect
            v-model="filter.subject"
            :items="orderSubjects"
            :font-size="'12px'"
            :style="{width: '10rem'}"
            placeholder="Предмет"
            @input="(val) => inputSelect(val, 'subject')"
          />
        </div>
        <div :class="$style.teachers__header_count">
          Общее количество пользователей: {{overall}}
        </div>
      </div>
      <TableTemplate
              :columns="columns"
              :items="totalItems"
              style="width: 100%;"
              @input="(teacher) => onSetTeacherDetails(teacher)"
      />
    </div>

    <div :class="$style.teachers__count">
      <div :class="$style.teachers__count_title">
        Выводить по:
      </div>
      <div :class="$style.teachers__count_list">
        <div
                v-for="{name, value} of countPerPage"
                :key="name"
                :class="{[$style.teachers__count_active]: activeCount === value}"
                @click="activeCount = value"
        >
          {{ name }}
        </div>
      </div>
    </div>

    <pagination
      :pageCount="teachersPageCount"
      :prevText="''"
      :click-handler="(value) => onTeachersPageChange(value, activeCount, filter)"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      :initial-page="$route.query.page - 1"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import orderBy from 'lodash/orderBy'
import Moment from 'moment'

import users from '@/api/users'
import ordersApi from '@/api/orders'
import paginationMixin from '@/mixins/pagination.mixin'
import filterMixin from '@/mixins/filter.mixin'

import Title from '@/basic/Title'
import Input from '@/basic/Input'
import TableTemplate from '../basic/TableTemplate'
import DefaultSelect from '../basic/DefaultSelect'

export default {
  name: 'Teachers',
  components: {
    Title,
    Input,
    TableTemplate,
    DefaultSelect
  },
  data() {
    return {
      teachers: [],
      filterValue: '',
      count:'',
      overall: 0,
      countPerPage: [
        {
          name: '10',
          value: 10
        },
        {
          name: '20',
          value: 20
        },
        {
          name: '50',
          value: 50
        },
        {
          name: 'вывести все',
          value: 100000
        }
      ],
      activeCount: 10,
      grades: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e) => {
        return {
          id: e,
          name: e + ' класс'
        }
      }),
      columns: [
        {
          column: 'ID',
          sort: 'id',
          width: 5,
          value: (row) => row.id
        },
        {
          column: 'Фио',
          sort: 'attributes.lastName',
          width: 20,
          value: (row) => [row.attributes.lastName, row.attributes.firstName, row.attributes.middleName].join(' ')
        },
        {
          column: 'Телефон',
          sort: 'phone',
          width: 13,
          value: (row) => row.phone
        },
        {
          column: 'Предмет',
          width: 15,
          value: (row) => row?.teacher?.subjects.map(e => e.subject.name).join(', ')
        },
        {
          column: 'Класс',
          width: 10,
          value: (row) => row?.teacher?.minAcceptableGrade ?
                  `c ${row?.teacher?.minAcceptableGrade} по ${row?.teacher?.maxAcceptableGrade} класс` :
                  ``
        },
        {
          column: 'Тип подготовки',
          width: 15,
          value: (row) => row?.teacher?.lessonsTypes.map(e => e.lessonType.name).join(', ')
        },
        {
          column: 'Статус',
          sort: 'teacher.isVerified',
          width: 10,
          value: (row, isTitle) => isTitle ?
                  row?.teacher?.isVerified ?
                          'Активен' :
                          'Неактивен' : row?.teacher?.isVerified ?
                          `<svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M0 26.016v-20q0-2.496 1.76-4.256t4.256-1.76h20q2.464 0 4.224 1.76t1.76 4.256v20q0 2.496-1.76 4.224t-4.224 1.76h-20q-2.496 0-4.256-1.76t-1.76-4.224zM4 26.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-20q-0.832 0-1.44 0.608t-0.576 1.408v20zM7.584 16q0-0.832 0.608-1.408t1.408-0.576 1.408 0.576l2.848 2.816 7.072-7.040q0.576-0.608 1.408-0.608t1.408 0.608 0.608 1.408-0.608 1.408l-8.48 8.48q-0.576 0.608-1.408 0.608t-1.408-0.608l-4.256-4.256q-0.608-0.576-0.608-1.408z" fill="green"></path></svg>` :
                          `<svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="Complete"><g id="x-circle"><g><circle cx="12" cy="12" data-name="--Circle" fill="none" id="_--Circle" r="10" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="14.5" x2="9.5" y1="9.5" y2="14.5"/><line fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="14.5" x2="9.5" y1="14.5" y2="9.5"/></g></g></g></svg>`
        },
        {
          column: 'Доступность',
          width: 12,
          value: (row) => [
                  ...new Set(
                          row?.teacher?.workingPeriod
                          .filter(e => Moment(e.start).isSame(Moment(), 'w'))
                          .map(e => this.weekDays[Moment(e.start).day()])
                  )
          ].join(', ')
        }
      ],
      orderSubjects: [],
      orderTeachingType: [],
      orderLessonType: [],
      weekDays: {
        1: 'пн',
        2: 'вт',
        3: 'ср',
        4: 'чт',
        5: 'пт',
        6: 'суб',
        0: 'воскр'
      },
      statuses: [
        {
          id: 0,
          name: 'Неактивный'
        },
        {
          id: 1,
          name: 'Активный'
        }
      ]
    }
  },
  mixins: [
    paginationMixin,
    filterMixin
  ],
  computed: {
    filteredTeachers() {
      if (this.filterValue === '') {
        return this.teachers
      }
      return this.teachers?.filter(
              (teacher) =>
                      (teacher && teacher.id.toString().indexOf(this.filterValue) >= 0) ||
                      teacher.email?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0 ||
                      teacher.phone?.toString().indexOf(this.filterValue.toLowerCase()) >= 0 ||
                      teacher.attributes.lastName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
                      0 ||
                      teacher.attributes.firstName
                              ?.toLowerCase()
                              .indexOf(this.filterValue.toLowerCase()) >= 0 ||
                      teacher.attributes.middleName
                              ?.toLowerCase()
                              .indexOf(this.filterValue.toLowerCase()) >= 0
      )
    }
  },
  watch: {
    filterValue: {
      handler() {
        this.$router.push(`?page=1`)
        this.setTeacher()
      }
    },
    activeCount (newValue, oldValue) {
      this.$router.push(`?page=1`)
      if (newValue !== oldValue) {
        this.setTeacher()
      }
    }
  },
  async mounted() {
    const {
      result
    } = await users.overallCount(3)
    this.overall = result

    const [orderInfo, ] = await Promise.all([
      ordersApi.getOrderInfo(),
      this.setTeacher()
    ])
    const { lessonsTypes, subjects, teachingTypes } = orderInfo.data.result

    this.orderSubjects = subjects
    this.orderTeachingType = teachingTypes
    this.orderLessonType = lessonsTypes.filter((lessonType) => lessonType.name !== 'Подготовка к ВПР/ОГЭ/ЕГЭ' && lessonType.name !== 'Подготовка к экзаменам')
  },
  methods: {
    ...mapMutations(['setTeacherDetails']),
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
    onSetTeacherDetails(teacherDetails) {
      this.setTeacherDetails(teacherDetails)
      this.$router.push(`/teachers/${teacherDetails.id}`)
    },
    async setTeacher () {
      const page = parseInt(this.$route.query.page)
      const res = await users.getTeachersWithLimit({
        offset: page * this.activeCount - this.activeCount,
        limit: this.activeCount
      }, this.filterValue, this.filter)
      this.teachers = orderBy(res.data.result.rows, 'id', this.sortMethod)
      this.count = res.data.result.count
      this.setupTeachersPagination(this.filteredTeachers, res.data.result.count, this.activeCount)
    },
    inputSelect (val, key) {
      if (!val) {
        delete this.filter[key]
      } else {
        this.$set(this.filter, key, val.id)
      }

      this.$router.push(`?page=1`)
      this.setTeacher()
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.teachers {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0.9rem 0 0 0;
    }
    &_count {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
    &_selects {
      display: flex;
      margin: auto 0 0 1rem;
      position: relative;
      height: 34px;
    }
  }

  &__count {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    &_title {
      font-size: 1rem;
    }
    &_list {
      display: flex;
      div {
        border: 1px solid #bebebe;
        padding: 0.2rem;
        cursor: pointer;
        margin-right: 10px;
        &:hover {
          background: #e7e4e4;
        }
      }
    }
    &_active {
      background: #e7e4e4;
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
